import React from "react"
import { Link } from 'gatsby'
import industryimage from "../../assets/images/banners/industry-images/energy.jpg"
// import offgrid from "../../assets/images/icons/mitech-box-image-style-06-image-01-120x120.webp"
import providing from "../../assets/images/icons/mitech-box-image-style-06-image-02-120x120.webp"
import billing from "../../assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp"

import offgrid from '../../assets/images/banners/icon-pack/grid.png'
import grid from '../../assets/images/banners/icon-pack/grid1.png'
import utilities from '../../assets/images/banners/icon-pack/home.png'
import facilities from '../../assets/images/banners/icon-pack/home-appliance.png'
import energy_management from '../../assets/images/banners/icon-pack/energy-management.png'
import meters from '../../assets/images/banners/icon-pack/power-meter.png'

import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

const Energy = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img energy"
          data-bg=""
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Energy & Utilities Solutions</h1>
                  <h5 className="font-weight--normal text-white">
                    IT solutions to power your business systems.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">
                    Our Industry Solution
                  </h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Energy & <span className="text-color-primary">Utilities Solutions</span></h2>
                  Optimizing energy generation and use is becoming more
                  important than ever as technology advances, and energy
                  infrastructure grows more complex. Aamanto helps
                  utility companies, energy equipment manufacturers, and
                  energy software suppliers develop scalable software
                  that gives them complete control over energy
                  distribution. We create comprehensive solutions to
                  track the performance of power transmission systems
                  and ensure that utilities are used efficiently in
                  private homes, business buildings, and industrial
                  plants.
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={industryimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

             <!--===========  feature-large-images-wrapper  Start =============--> */}
        <div className="feature-large-images-wrapper bg-gray section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">What Services <span className="text-color-primary">we Offer</span></h2>
                </div>
                {/* <!-- section-title-wrap Start --> */}
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="feature-images__six">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={offgrid} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Off Grid Systems</h5>
                            <div className="text">
                              Rack and manage physical plants such as wind or
                              solar farms with relevant analytics and work.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src={providing}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">
                              Providing Smart Facilities
                            </h5>
                            <div className="text">
                              Integrate construction methods for precise
                              energy-related service plant management.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={billing} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Billing for utilities</h5>
                            <div className="text">
                              To streamline billing communications, reduce the
                              number of stages in your invoicing process.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={billing} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">
                              Data Processing in Smart Grid{" "}
                            </h5>
                            <div className="text">
                              Predictive analytics can help you improve
                              performance and avoid problems.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src={providing}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">
                              Data management for meters{" "}
                            </h5>
                            <div className="text">
                              For correct billing determinants, combine data
                              from meters.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={offgrid} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Energy Management</h5>
                            <div className="text">
                              Track and anticipate utility consumption to unlock
                              energy efficiency savings
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="v1-box-2-parent">
              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={offgrid} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Off Grid Systems</h5>
                            <div className="v1-text-2">
                              Rack and manage physical plants such as wind or solar farms with relevant analytics and work.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={facilities} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Providing Smart Facilities</h5>
                            <div className="v1-text-2">
                              Integrate construction methods for precise energy-related service plant management.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={utilities} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Billing for utilities</h5>
                            <div className="v1-text-2">
                              To streamline billing communications, reduce the number of stages in your invoicing process.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={grid} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Data Processing in Smart Grid</h5>
                            <div className="v1-text-2">
                              Predictive analytics can help you improve performance and avoid problems.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={meters} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Data management for meters</h5>
                            <div className="v1-text-2">
                              For correct billing determinants, combine data from meters.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={energy_management} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Energy Management</h5>
                            <div className="v1-text-2">
                              Track and anticipate utility consumption to unlock energy efficiency savings.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>

          </div>
        </div>
        {/* <!--===========  feature-large-images-wrapper  End =============-->
            <!--=========== About Company Area End ==========--> */}
      </div>
    </div>
  )
}

export default Energy
