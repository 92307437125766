import * as React from "react"
import Layout from "../components/layout"
import Energy from "../components/Industries/Energy"






const energy = () => {
  return (
  <Layout>
   <Energy/>
  </Layout>
)
};
export default energy;
